import React from "react";
import * as Chakra from "@chakra-ui/react";
import LikeSingleColor from "../../images/like-single-color.svg";
import CommentSingleColor from "../../images/comment-single-color.svg";
import ShareSingleColor from "../../images/share-single-color.svg";
import { darken } from "@chakra-ui/theme-tools";
import FacebookState, { PossibleReactions } from "./facebook-state-machine";

interface FacebookAdMockupProps {
  isInRender?: boolean;
}

export const FacebookAdMockup = ({
  isInRender = false,
}: FacebookAdMockupProps) => {
  const {
    pageName,
    message,
    callToAction,
    linkHeadline,
    linkDescription,
    linkCaption,
    pageShares,
    pageComments,
    reactionCount,
    reactionTypes,
    pageLogo,
    pageImage,
  } = FacebookState.useGlobalState((gs) => gs);

  return (
    <Chakra.Box
      backgroundColor="#FFFFFF"
      borderRadius="6px"
      p="15px"
      width="100%"
    >
      <Chakra.Flex justifyContent="space-between" alignItems="center">
        <Chakra.Flex>
          <Chakra.Box
            width="44px"
            height="44px"
            bgColor="#3B5998"
            backgroundImage={pageLogo ? `url(${pageLogo})` : undefined}
            backgroundPosition="center center"
            backgroundSize="cover"
          />
          <Chakra.Flex
            pl="10px"
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
          >
            <Chakra.Text
              fontSize="14px"
              letterSpacing="0.11px"
              fontWeight="500"
              color="#3B5998"
            >
              {pageName ? pageName : "Your Page Here"}
            </Chakra.Text>
            <Chakra.Text
              pb="4px"
              fontSize="11px"
              fontWeight="300"
              letterSpacing="0.09px"
              color="#9097A3"
            >
              Sponsored
            </Chakra.Text>
          </Chakra.Flex>
        </Chakra.Flex>
        <ButtonShell>
          <LikeSingleColor />
          <Chakra.Text as="span" pl="8px" marginTop={isInRender && "-11px"}>
            Like Page
          </Chakra.Text>
        </ButtonShell>
      </Chakra.Flex>
      <Chakra.Text py="13px" fontSize="14px" fontWeight="400">
        {message ? message : "Your Message Here"}
      </Chakra.Text>
      <Chakra.Box boxShadow="0 2px 4px #0003" borderRadius="2px">
        <Chakra.Flex
          backgroundColor="#3B5998"
          height="205px"
          width="100%"
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundImage={pageLogo ? `url(${pageImage})` : undefined}
        />
        <Chakra.Box px="15px" py="12px">
          <Chakra.Text fontFamily="Georgia" fontSize="17px" color="#0F0F0F">
            {linkHeadline ? linkHeadline : "Your Message Here"}
          </Chakra.Text>
          <Chakra.Text fontSize="12px" color="#0F0F0F">
            {linkDescription ? linkDescription : "Your Description Here"}
          </Chakra.Text>
          <Chakra.Flex
            pt="25px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Chakra.Text
              color="#9097A3"
              fontSize="10px"
              fontWeight="300"
              textTransform="uppercase"
              letterSpacing="0.4px"
              marginTop={isInRender && "-11px"}
            >
              {linkCaption ? linkCaption : "Your Caption Here"}
            </Chakra.Text>
            <ButtonShell>
              <Chakra.Box as="span" marginTop={isInRender && "-11px"}>
                {callToAction ? callToAction : "Learn More"}
              </Chakra.Box>
            </ButtonShell>
          </Chakra.Flex>
        </Chakra.Box>
      </Chakra.Box>
      <Chakra.Flex pt="18px" justifyContent="space-between" pb="10px">
        <Chakra.Flex css={{ svg: { width: "16px", height: "16px" } }}>
          {PossibleReactions.filter((x) => reactionTypes.includes(x.name)).map(
            (reaction) => (
              <reaction.image />
            )
          )}
          <Chakra.Text
            pl="5px"
            as="div"
            color="#9097A3"
            fontSize="11px"
            marginTop={isInRender && "-5px"}
          >
            {reactionCount ? reactionCount : "20"}
          </Chakra.Text>
        </Chakra.Flex>
        <Chakra.HStack spacing="10px">
          <Chakra.Text as="div" color="#9097A3" fontSize="11px">
            {pageComments ? pageComments : "562"} Comments
          </Chakra.Text>
          <Chakra.Text as="div" color="#9097A3" fontSize="11px">
            {pageShares ? pageShares : "311"} Shares
          </Chakra.Text>
        </Chakra.HStack>
      </Chakra.Flex>
      <Chakra.Divider />
      <Chakra.HStack pt="10px" justifyContent="start" spacing="30px">
        <Chakra.Flex
          css={{ svg: { width: "16px", height: "16px" } }}
          alignItems="center"
          justifyContent="center"
        >
          <LikeSingleColor />
          <Chakra.Text
            pl="8px"
            as="div"
            color="#7F7F7F"
            fontSize="12px"
            fontWeight="500"
            marginTop={isInRender && "-4px"}
          >
            Like
          </Chakra.Text>
        </Chakra.Flex>
        <Chakra.Flex
          css={{ svg: { width: "16px", height: "16px", marginTop: "4px" } }}
          alignItems="center"
          justifyContent="center"
        >
          <CommentSingleColor />
          <Chakra.Text
            pl="8px"
            as="div"
            color="#7F7F7F"
            fontSize="12px"
            fontWeight="500"
            marginTop={isInRender && "-4px"}
          >
            Comment
          </Chakra.Text>
        </Chakra.Flex>
        <Chakra.Flex
          css={{ svg: { width: "16px", height: "16px", marginTop: "2px" } }}
          alignItems="center"
          justifyContent="center"
        >
          <ShareSingleColor />
          <Chakra.Text
            pl="8px"
            as="div"
            color="#7F7F7F"
            fontSize="12px"
            fontWeight="500"
            marginTop={isInRender && "-4px"}
          >
            Share
          </Chakra.Text>
        </Chakra.Flex>
      </Chakra.HStack>
    </Chakra.Box>
  );
};

const ButtonShell = (props: Chakra.FlexProps) => {
  return (
    <Chakra.Flex
      transition="0.2s"
      cursor="pointer"
      _hover={{ backgroundColor: darken("#f5f6f7", 3) }}
      px="10px"
      lineHeight="23px"
      borderRadius="2px"
      verticalAlign="middle"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      fontSize="12px"
      height="28px"
      w="102px"
      color="#4b4f56"
      fontWeight="500"
      backgroundColor="#f5f6f7"
      border="1px solid #ccd0d5"
      {...props}
    />
  );
};
