import React from "react";
import * as Chakra from "@chakra-ui/react";
import { MenuTile } from "./menu-tile";
import { WindowLocation } from "@reach/router";
import { Pivot as Hamburger } from "hamburger-react";

interface Header {
  location: WindowLocation;
}

const Header = ({ location }: Header): React.ReactElement => {
  const { isOpen, onOpen, onClose } = Chakra.useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Chakra.Box
        pos="sticky"
        top="0"
        as="header"
        width="100%"
        transition="1s ease-out"
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-end"
        bgColor="#F8F7F6"
        zIndex="20"
        padding={{ base: "30px", lg: "0" }}
      >
        <Chakra.Box
          cursor="pointer"
          d={{ lg: "none" }}
          onClick={isOpen ? onClose : onOpen}
          ref={btnRef}
        >
          <Hamburger toggled={isOpen} />
        </Chakra.Box>
        <Chakra.Flex
          width="100%"
          d={{ base: "none", lg: "flex" }}
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <MenuTile to="/google" location={location}>
            Google
          </MenuTile>
          <MenuTile to="/facebook" location={location}>
            Facebook
          </MenuTile>
          <MenuTile to="/email-newsletter" location={location}>
            Email Newsletter
          </MenuTile>
          <MenuTile to="/landing-page" location={location}>
            Landing Page
          </MenuTile>
          <MenuTile to="/resources" location={location}>
            Resources
          </MenuTile>
        </Chakra.Flex>
      </Chakra.Box>
      <Chakra.Drawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        placement="bottom"
        blockScrollOnMount={false}
      >
        <Chakra.DrawerOverlay />
        <Chakra.DrawerContent>
          <Chakra.Flex
            width="100%"
            direction="column"
            justifyContent="flex-end"
          >
            <MenuTile to="/google" location={location}>
              Google
            </MenuTile>
            <MenuTile to="/facebook" location={location}>
              Facebook
            </MenuTile>
            <MenuTile to="/email-newsletter" location={location}>
              Email Newsletter
            </MenuTile>
            <MenuTile to="/landing-page" location={location}>
              Landing Page
            </MenuTile>
            <MenuTile to="/resources" location={location}>
              Resources
            </MenuTile>
          </Chakra.Flex>
        </Chakra.DrawerContent>
      </Chakra.Drawer>
    </>
  );
};

export default Header;
