import React from "react";
import * as Chakra from "@chakra-ui/react";
import { FormScrollArea } from "./form-scroll-area";

export interface ModuleContainerProps extends Chakra.BoxProps {
  doMaxWidth?: boolean;
}

export const ModuleContainer = ({
  doMaxWidth,
  ...props
}: ModuleContainerProps): React.ReactElement => {
  return (
    <Chakra.Flex
      width="100%"
      flexDir={{ base: "column", lg: "row" }}
      align="start"
      justify="center"
      position="sticky"
      top="0"
      bottom="0"
      left="0"
      right="0"
      overflowY="hidden"
      height="100%"
    >
      <Chakra.Flex width="100%" height="100%" flexGrow={1}>
        <Chakra.VStack
          justify="start"
          align="start"
          width="100%"
          height="100%"
          pl={{ base: "20px", lg: "82px" }}
          pr={{ base: "20px", lg: "0" }}
          flexGrow={1}
        >
          <FormScrollArea
            marginLess
            py={{ base: "20px", lg: "80px" }}
            maxWidth={doMaxWidth ? "908px" : undefined}
            {...props}
          />
        </Chakra.VStack>
      </Chakra.Flex>
    </Chakra.Flex>
  );
};

export const ModuleContainerNoPadding = (
  props: ModuleContainerProps
): React.ReactElement => {
  return (
    <Chakra.Flex
      width="100%"
      flexDir={{ base: "column", lg: "row" }}
      align="start"
      justify="center"
      position="sticky"
      top="0"
      bottom="0"
      left="0"
      right="0"
      overflowY="hidden"
      height="100%"
    >
      <Chakra.Flex width="100%" height="100%" flexGrow={1}>
        <Chakra.VStack
          justify="start"
          align="start"
          width="100%"
          height="100%"
          flexGrow={1}
        >
          <FormScrollArea marginLess pr="0" {...props} />
        </Chakra.VStack>
      </Chakra.Flex>
    </Chakra.Flex>
  );
};
