import React from "react";
import * as Chakra from "@chakra-ui/react";

export const HeadingLarge = (props: Chakra.TextProps): React.ReactElement => {
  return (
    <Chakra.Text
      as="h1"
      fontWeight="500"
      fontSize="40px"
      lineHeight="48px"
      letterSpacing="0.32px"
      {...props}
    />
  );
};
