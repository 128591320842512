import React from "react";
import * as Chakra from "@chakra-ui/react";
import Logo from "../../images/logo.svg";
import { lighten } from "@chakra-ui/theme-tools";
import LandingPageState from "./landing-page-state-machine";
import { ImageLum } from "../../utils/image-luminosity";
import TempIcon from "../../images/temp-icon.png";

export interface PageProps {
  scale: number;
}

export const LandingPageMockup = ({ scale }: PageProps) => {
  const {
    valueProposition,
    subHeader,
    bodyCopy,
    iconBlocks,
      bodySignOff,
    callOut,
    ctaButton,
    coverImage,
  } = LandingPageState.useGlobalState((gs) => gs);
  const [coverImageIsDark, setCoverImageIsDark] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    if (coverImage && typeof window !== "undefined") {
      ImageLum(coverImage, (value) => {
        if (value < 150) {
          setCoverImageIsDark(true);
        } else {
          setCoverImageIsDark(false);
        }
      });
    }
  }, [coverImage]);

  const doScale = (num) => {
    let pxVal = parseFloat(num.replace("px", ""));
    return `${pxVal * scale}px`;
  };

  return (
    <Chakra.Flex
      width="100%"
      height="100%"
      flexDirection="column"
      position="relative"
      borderRadius="6px"
    >
      <Chakra.Flex
        backgroundColor="#fff"
        px={doScale("30px")}
        py={doScale("15px")}
        justifyContent="space-between"
        alignItems="center"
      >
        <Chakra.Box>
          <Logo />
        </Chakra.Box>
        <Chakra.Box fontSize={doScale("8px")} color="#272726">
          Menu
        </Chakra.Box>
      </Chakra.Flex>
      <Chakra.Flex
        py={doScale("65px")}
        px={doScale("30px")}
        backgroundColor="#F2F2F2"
        flexDirection="column"
        color={coverImageIsDark ? "#fff" : "#000"}
        backgroundImage={coverImage ? `url(${coverImage})` : undefined}
        backgroundPosition="center center"
        backgroundSize="cover"
      >
        <Chakra.Box width="50%">
        <Chakra.Text
          fontSize={doScale("12px")}
          fontWeight="500"
          letterSpacing={doScale("0.1px")}
        >
          {valueProposition ? valueProposition : "Heading here"}
        </Chakra.Text>
        <Chakra.Text
          fontSize={doScale("9px")}
          lineHeight={doScale("15px")}
          letterSpacing={doScale("0.07px")}
        >
          {subHeader
            ? subHeader
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,"}
        </Chakra.Text>
        </Chakra.Box>
      </Chakra.Flex>
      <Chakra.Flex
        backgroundColor="#FFFFFF"
        flexDirection="column"
        px={doScale("30px")}
      >
        <Chakra.Text
          maxWidth={doScale("300px")}
          m="auto"
          fontSize={doScale("9px")}
          py={doScale("25px")}
          textAlign="center"
          lineHeight={doScale("15px")}
          letterSpacing={doScale("0.07px")}
          color="#000"
          dangerouslySetInnerHTML={{
            __html: bodyCopy
              ? bodyCopy
              : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>",
          }}
        />
        <Chakra.SimpleGrid columns={4} spacing={10}>
          {iconBlocks.map((data) => (
            <Chakra.VStack spacing={doScale("10px")} textAlign="center">
              <Chakra.Image
                pb={doScale("5px")}
                maxWidth={doScale("23px")}
                maxHeight={doScale("23px")}
                objectFit="contain"
                src={data.icon ? data.icon : TempIcon}
              />
              <Chakra.Text
                fontSize={doScale("9px")}
                letterSpacing={doScale("0.07px")}
                fontWeight="500"
              >
                {data.header ? data.header : "Lorem ipsum dolor sit amet."}
              </Chakra.Text>
              <Chakra.Text
                fontSize={doScale("8px")}
                lineHeight={doScale("14px")}
                letterSpacing={doScale("0.06px")}
                fontWeight="500"
              >
                {data.subHeader
                  ? data.subHeader
                  : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,."}
              </Chakra.Text>
            </Chakra.VStack>
          ))}
        </Chakra.SimpleGrid>
        <Chakra.Text
          pt={doScale("34px")}
          maxWidth={doScale("300px")}
          textAlign="center"
          m="auto"
          pb={doScale("34px")}
          fontSize={doScale("6px")}
          letterSpacing={doScale("0.05px")}
          lineHeight={doScale("10px")}
          dangerouslySetInnerHTML={{
            __html: bodySignOff
              ? bodySignOff
              : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>",
          }}
        />
      </Chakra.Flex>
      <Chakra.Box
        textAlign="center"
        backgroundColor="#F2F2F2"
        flexDirection="column"
        py={doScale("20px")}
        px={doScale("30px")}
      >
        <Chakra.Text
          maxWidth={doScale("300px")}
          m="auto"
          pb={doScale("12px")}
          fontSize={doScale("10px")}
          letterSpacing={doScale("0.08px")}
          fontWeight="500"
          dangerouslySetInnerHTML={{
            __html: callOut
                ? callOut
                : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>",
          }}
       />
        <Chakra.Box
          transition="0.3s"
          _hover={{ backgroundColor: lighten("#1A0EAB", 14) }}
          cursor="pointer"
          m="auto"
          display="inline-block"
          fontSize={doScale("8px")}
          letterSpacing={doScale("0.06px")}
          backgroundColor="#1A0EAB"
          py={doScale("7px")}
          px={doScale("10px")}
          color="#fff"
          width="auto"
        >
          {ctaButton ? ctaButton : "CTA Button"}
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Flex>
  );
};
