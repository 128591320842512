import React from "react";
import * as Chakra from "@chakra-ui/react";
import { darken } from "@chakra-ui/theme-tools";
import { Link } from "gatsby";
import { WindowLocation } from "@reach/router";

export interface MenuTileProps extends Chakra.ButtonProps {
  to: string;
  location: WindowLocation;
}

export const MenuTile = ({
  to,
  location,
  ...props
}: MenuTileProps): React.ReactElement => {
  const currentPath = location.pathname;

  return (
    <Chakra.Button
      as={Link}
      to={to}
      borderRadius="0"
      fontSize="16px"
      transition="0.3s"
      lineHeight="16px"
      width={{ base: "auto", xl: "100%" }}
      px={{ base: "20px", xl: "10px" }}
      maxWidth={{ lg: "175px", xl: "195px" }}
      fontWeight="400"
      py="25px"
      mr="1px"
      backgroundColor={currentPath === to ? "#FFFFFF" : "#EEEDEC"}
      height="auto"
      _hover={{
        backgroundColor: currentPath === to ? "#FFFFFF" : darken("#EEEDEC", 3),
      }}
      _focus={{
        backgroundColor: currentPath === to ? "#FFFFFF" : darken("#EEEDEC", 5),
      }}
      _active={{
        backgroundColor: currentPath === to ? "#FFFFFF" : darken("#EEEDEC", 5),
      }}
      {...props}
    />
  );
};
