import { GlobalState } from "react-gstate";

export interface EmailNewsletterStateMachineProps {
  valueProposition: string;
  bodyCopy: string;
  dotPoints: string[];
  bodySignOff: string;
  buttonCta: string;
  emailSubject: string;
  emailPreHeaderLine: string;
  coverImage: string;
}

class EmailNewsletterStateMachine extends GlobalState<EmailNewsletterStateMachineProps> {
  setValueProposition = (input: string, count: number) => {
    // validation
    EmailNewsletterState.setState({ valueProposition: input.substring(0, count) });
  };

  setBodyCopy = (input: string, count: number) => {
    EmailNewsletterState.setState({ bodyCopy: input.substring(0, count) });
  };

  setDotPoint = (input: string, index: number, count: number) => {
    const existingDotPoints = [...EmailNewsletterState.state.dotPoints];
    existingDotPoints[index] = input.substring(0, count);
    EmailNewsletterState.setState({ dotPoints: existingDotPoints });
  };

  setBodySignOff = (input: string, count: number) => {
    EmailNewsletterState.setState({ bodySignOff: input.substring(0, count) });
  };

  setButtonCta = (input: string) => {
    EmailNewsletterState.setState({ buttonCta: input });
  };

  setEmailSubject = (input: string) => {
    EmailNewsletterState.setState({ emailSubject: input });
  };

  setEmailPreHeaderLine = (input: string) => {
    EmailNewsletterState.setState({ emailPreHeaderLine: input });
  };

  setCoverImage = (input: File) => {
    if (EmailNewsletterState.state.coverImage) {
      try {
        URL.revokeObjectURL(EmailNewsletterState.state.coverImage);
      } catch (e) {
        console.log(e);
      }
    }
    const blobUrl = URL.createObjectURL(input);
    EmailNewsletterState.setState({ coverImage: blobUrl });
  };
}

const EmailNewsletterState = new EmailNewsletterStateMachine({
  valueProposition: "",
  bodyCopy: "",
  dotPoints: ["", "", "", ""],
  bodySignOff: "",
  buttonCta: "",
  emailSubject: "",
  emailPreHeaderLine: "",
  coverImage: "",
});

export default EmailNewsletterState;
