import React from "react";
import * as Chakra from "@chakra-ui/react";

interface FormScrollAreaProps extends Chakra.StackProps {
  marginLess?: boolean;
}

export const FormScrollArea = React.forwardRef(
  (
    { marginLess, ...props }: FormScrollAreaProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <Chakra.Flex
        overflowY={{ lg: "auto" }}
        flexGrow={1}
        width="100%"
        ref={ref}
        css={{
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "100px",
            backgroundColor: "#EEEDEC",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#272726",
            borderRadius: "100px",
            height: "32px",
          },
        }}
      >
        <Chakra.VStack
          height={{
            lg: marginLess ? "calc(100vh - 126px)" : "calc(100vh - 286px)",
          }}
          width="100%"
          align="start"
          justify="start"
          pr={{ lg: "35px" }}
          {...props}
        />
      </Chakra.Flex>
    );
  }
);

FormScrollArea.displayName = "FormScrollArea";
