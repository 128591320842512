import { GlobalState } from "react-gstate";

interface IconBlockProps {
  icon: string;
  header: string;
  subHeader: string;
}

export interface LandingPageStateMachineProps {
  valueProposition: string;
  subHeader: string;
  bodyCopy: string;
  iconBlocks: IconBlockProps[];
  bodySignOff: string;
  callOut: string;
  ctaButton: string;
  coverImage: string;
}

class LandingPageStateMachine extends GlobalState<LandingPageStateMachineProps> {
  setValueProposition = (input: string, maxCount: number) => {
    LandingPageState.setState({ valueProposition: input.substring(0, maxCount) });
  };

  setSubHeader = (input: string, maxCount: number) => {
    LandingPageState.setState({ subHeader: input.substring(0, maxCount) });
  };

  setBodyCopy = (input: string, maxCount: number) => {
    LandingPageState.setState({ bodyCopy: input.substring(0, maxCount) });
  };

  setIconBlockHeader = (input: string, index: number, maxCount: number) => {
    const existingDotPoints = [...LandingPageState.state.iconBlocks];
    delete existingDotPoints[index];
    const newO = { ...LandingPageState.state.iconBlocks[index] };
    newO.header = input.substring(0, maxCount);
    existingDotPoints[index] = newO;
    LandingPageState.setState({ iconBlocks: existingDotPoints });
  };

  setIconBlockSubHeader = (input: string, index: number, maxCount: number) => {
    const existingDotPoints = [...LandingPageState.state.iconBlocks];
    delete existingDotPoints[index];
    const newO = { ...LandingPageState.state.iconBlocks[index] };
    newO.subHeader = input.substring(0, maxCount);
    existingDotPoints[index] = newO;
    LandingPageState.setState({ iconBlocks: existingDotPoints });
  };

  setIconBlockIcon = (input: File, index: number) => {
    const existingDotPoints = [...LandingPageState.state.iconBlocks];
    delete existingDotPoints[index];
    const newO = { ...LandingPageState.state.iconBlocks[index] };
    if (LandingPageState.state.iconBlocks[index].icon) {
      try {
        URL.revokeObjectURL(LandingPageState.state.iconBlocks[index].icon);
      } catch (e) {
        console.log(e);
      }
    }
    newO.icon = URL.createObjectURL(input);
    existingDotPoints[index] = newO;
    LandingPageState.setState({ iconBlocks: existingDotPoints });
  };

  setBodySignOff = (input: string, maxCount: number) => {
    LandingPageState.setState({ bodySignOff: input.substring(0, maxCount) });
  };

  setCallOut = (input: string, maxCount: number) => {
    LandingPageState.setState({ callOut: input.substring(0, maxCount) });
  };

  setCtaButton = (input: string, maxCount: number) => {
    LandingPageState.setState({ ctaButton: input.substring(0, maxCount) });
  };

  setCoverImage = (input: File) => {
    if (LandingPageState.state.coverImage) {
      try {
        URL.revokeObjectURL(LandingPageState.state.coverImage);
      } catch (e) {
        console.log(e);
      }
    }
    const blobUrl = URL.createObjectURL(input);
    LandingPageState.setState({ coverImage: blobUrl });
  };
}

const LandingPageState = new LandingPageStateMachine({
  valueProposition: "",
  subHeader: "",
  bodyCopy: "",
  iconBlocks: [
    { icon: "", header: "", subHeader: "" },
    { icon: "", header: "", subHeader: "" },
    { icon: "", header: "", subHeader: "" },
    { icon: "", header: "", subHeader: "" },
  ],
  bodySignOff: "",
  callOut: "",
  ctaButton: "",
  coverImage: "",
});

export default LandingPageState;
