import React from "react";
import * as Chakra from "@chakra-ui/react";
import FadeIn from "react-fade-in";
import GoogleState from "./google-state-machine";

interface GoogleMockupProps {
  isInRender?: boolean;
}

export const GoogleMockup = ({ isInRender = false }: GoogleMockupProps) => {
  const ads = GoogleState.useGlobalState((gs) => gs.ads);

  return (
    <Chakra.Flex
      width="100%"
      height="100%"
      flexDirection="column"
      position="relative"
      borderRadius="6px"
    >
      <Chakra.VStack spacing="27px" maxWidth="350px" m="auto">
        <FadeIn>
          {ads.map(
            ({
              headline1,
              headline2,
              headline3,
              description1,
              description2,
              displayUrl,
            }) => (
              <Chakra.Box pb="27px">
                <Chakra.Text
                  color="#1A0EAB"
                  fontSize="18px"
                  letterSpacing="0.14px"
                >
                  {headline1 ? headline1 : "Example Headline 1"} |{" "}
                  {headline2 ? headline2 : "Awesome Headline 2"} |{" "}
                  {headline3 ? headline3 : "Awesome Headline 3"}
                </Chakra.Text>
                <Chakra.Flex alignItems="center">
                  {!isInRender && (
                    <Chakra.Box
                      lineHeight="12px"
                      display="inline-table"
                      alignItems="center"
                      px="2px"
                      fontSize="10.5px"
                      letterSpacing="0.08px"
                      borderRadius="3px"
                      border="1px solid #006621"
                    >
                      Ad
                    </Chakra.Box>
                  )}
                  <Chakra.Text
                    pl={!isInRender && "6px"}
                    color="#006621"
                    fontSize="14px"
                    letterSpacing="0.11px"
                  >
                    {displayUrl ? displayUrl : "www.example.com/ppc-services"}
                  </Chakra.Text>
                </Chakra.Flex>
                <Chakra.Text
                  pt="2px"
                  color="#5C5C5C"
                  fontSize="13px"
                  letterSpacing="0.1px"
                >
                  {description1 ? description1 : "Create Some Amazing Ad Copy."}{" "}
                  {description2 ? description2 : "Make your ad stand out!"}
                </Chakra.Text>
              </Chakra.Box>
            )
          )}
        </FadeIn>
      </Chakra.VStack>
    </Chakra.Flex>
  );
};
