import React from "react";
import * as Chakra from "@chakra-ui/react";
import { WysiwygWithScale } from "../wysiwyg";
import { lighten } from "@chakra-ui/theme-tools";
import EmailNewsletterState from "./email-newsletter-state-machine";

export interface PageProps {
  scale: number;
}

export const EmailNewsletterMockup = ({ scale }: PageProps) => {
  const {
    buttonCta,
    bodySignOff,
    bodyCopy,
    dotPoints = [],
    valueProposition,
    coverImage,
  } = EmailNewsletterState.useGlobalState((gs) => gs);

  const doScale = (num) => {
    let pxVal = parseFloat(num.replace("px", ""));
    return `${pxVal * scale}px`;
  };

  return (
    <Chakra.Flex
      width="100%"
      height="100%"
      flexDirection="column"
      position="relative"
      borderRadius="6px"
    >
      <Chakra.Flex
        alignItems="center"
        justifyContent="center"
        backgroundColor="white"
        pb={doScale("36px")}
      >
        <Chakra.Flex
          width="100%"
          backgroundColor="#F2F2F2"
          px={doScale("36px")}
          alignItems="center"
          justifyContent="center"
          minHeight={doScale("245px")}
          backgroundPosition="center center"
          backgroundSize="cover"
          backgroundImage={coverImage ? `url(${coverImage})` : undefined}
        >
          <Chakra.Text
            fontWeight="500"
            fontSize={doScale("18px")}
            lineHeight={doScale("30px")}
            color="#040404"
            letterSpacing={doScale("0.14px")}
          >
            {valueProposition
              ? valueProposition
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed"}
          </Chakra.Text>
        </Chakra.Flex>
      </Chakra.Flex>
      <Chakra.Flex
        pb={doScale("45px")}
        px={doScale("36px")}
        flexDirection="column"
        backgroundColor="white"
      >
        <WysiwygWithScale
          scale={scale}
          fontWeight="300"
          pb="15px"
          dangerouslySetInnerHTML={{
            __html: bodyCopy
              ? bodyCopy
              : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
          }}
        />
        <WysiwygWithScale scale={scale} fontWeight="300" pb="15px">
          <ul>
            <li>
              {dotPoints[0]
                ? dotPoints[0]
                : "Lorem ipsum dolor sit amet, consectetur"}
            </li>
            <li>
              {dotPoints[1]
                ? dotPoints[1]
                : "Lorem ipsum dolor sit amet, consectetur"}
            </li>
            <li>
              {dotPoints[2]
                ? dotPoints[2]
                : "Lorem ipsum dolor sit amet, consectetur"}
            </li>
            <li>
              {dotPoints[3]
                ? dotPoints[3]
                : "Lorem ipsum dolor sit amet, consectetur"}
            </li>
          </ul>
        </WysiwygWithScale>
        <WysiwygWithScale
          scale={scale}
          fontWeight="300"
          pb={doScale("15px")}
          dangerouslySetInnerHTML={{
            __html: bodySignOff
              ? bodySignOff
              : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>",
          }}
        />
        <Chakra.Box>
          <Chakra.Box
            m="auto"
            _hover={{ backgroundColor: lighten("#1A0EAB", 14) }}
            cursor="pointer"
            transition="0.3s"
            display="inline-block"
            fontSize={doScale("12px")}
            letterSpacing={doScale("0.1px")}
            backgroundColor="#1A0EAB"
            py={doScale("13px")}
            px={doScale("21px")}
            color="#fff"
            width="auto"
          >
            {buttonCta ? buttonCta : "Get Started"}
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.Flex>
    </Chakra.Flex>
  );
};
