import React from "react";
import * as Chakra from "@chakra-ui/react";

export const Wysiwyg = (props: Chakra.TextProps): React.ReactElement => {
  return (
    <Chakra.Text
      as="div"
      color="#000"
      css={{
        h2: {
          fontSize: "20px",
          lineHeight: "20px",
          letterSpacing: "0.16px",
          paddingBottom: "40px",
        },
        p: {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0.11px",
          paddingBottom: "28px",
        },
        "ul, ol": {
          paddingBottom: "28px",
          width: "fit-content",
          margin: "auto",
        },
        li: {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0.11px",
          marginLeft: "20px",
          paddingBottom: "10px",
        },
      }}
      {...props}
    />
  );
};

interface WysiwygWithScaleProps extends Chakra.TextProps {
  scale: number;
}

export const WysiwygWithScale = ({
  scale,
  ...props
}: WysiwygWithScaleProps): React.ReactElement => {
  const doScale = (num) => {
    const pxVal = parseFloat(num.replace("px", ""));
    return `${pxVal * scale}px`;
  };

  return (
    <Chakra.Text
      as="div"
      color="#000"
      css={{
        h2: {
          fontSize: doScale("20px"),
          lineHeight: doScale("20px"),
          letterSpacing: doScale("0.16px"),
          paddingBottom: doScale("40px"),
        },
        p: {
          fontSize: doScale("14px"),
          lineHeight: doScale("20px"),
          letterSpacing: doScale("0.11px"),
          paddingBottom: doScale("28px"),
        },
        "ul, ol": {
          paddingBottom: doScale("28px"),
          width: "fit-content",
          margin: "auto",
        },
        li: {
          fontSize: doScale("14px"),
          lineHeight: doScale("20px"),
          letterSpacing: doScale("0.11px"),
          marginLeft: doScale("20px"),
          paddingBottom: doScale("10px"),
        },
      }}
      {...props}
    />
  );
};
