import React from "react";
import * as Chakra from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { HeadingLarge } from "./heading-large";
import FPLargeShape from "../images/fp-large-shape.svg";
import { ModuleContainerNoPadding } from "./module-container";
import FadeTransition from "../components/transitions/fade-transition";

interface StartWrapperProps {
  children: React.ReactNode;
  shouldChange: string;
}

export const StartWrapper = ({
  children,
  shouldChange,
}: StartWrapperProps): React.ReactElement => {
  return (
    <Chakra.Box width="100%">
      <ModuleContainerNoPadding>
        <Chakra.Flex
          alignItems="center"
          position="relative"
          minHeight="32vh"
          width="100%"
        >
          <Chakra.Box position="absolute" height="100%" width="100%">
            <StaticImage
              objectFit="cover"
              style={{ height: "100%" }}
              imgStyle={{ height: "100%" }}
              alt=""
              src="../images/intro-page-background.png"
            />
          </Chakra.Box>
          <Chakra.Flex
            maxWidth="908px"
            px="40px"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            m="auto"
            width="100%"
            position="relative"
          >
            <Chakra.Box>
              <HeadingLarge color="#fff" maxWidth="350px">
                Experimentation is the answer.
              </HeadingLarge>
            </Chakra.Box>
            <Chakra.Box py="40px" display={{ base: "none", lg: "unset" }}>
              <FPLargeShape />
            </Chakra.Box>
          </Chakra.Flex>
        </Chakra.Flex>
        <FadeTransition shouldChange={shouldChange}>{children}</FadeTransition>
      </ModuleContainerNoPadding>
    </Chakra.Box>
  );
};
