// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-email-newsletter-tsx": () => import("./../../../src/pages/email-newsletter.tsx" /* webpackChunkName: "component---src-pages-email-newsletter-tsx" */),
  "component---src-pages-facebook-tsx": () => import("./../../../src/pages/facebook.tsx" /* webpackChunkName: "component---src-pages-facebook-tsx" */),
  "component---src-pages-finish-tsx": () => import("./../../../src/pages/finish.tsx" /* webpackChunkName: "component---src-pages-finish-tsx" */),
  "component---src-pages-google-tsx": () => import("./../../../src/pages/google.tsx" /* webpackChunkName: "component---src-pages-google-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-tsx": () => import("./../../../src/pages/landing-page.tsx" /* webpackChunkName: "component---src-pages-landing-page-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-start-2-tsx": () => import("./../../../src/pages/start/2.tsx" /* webpackChunkName: "component---src-pages-start-2-tsx" */),
  "component---src-pages-start-3-tsx": () => import("./../../../src/pages/start/3.tsx" /* webpackChunkName: "component---src-pages-start-3-tsx" */),
  "component---src-pages-start-index-tsx": () => import("./../../../src/pages/start/index.tsx" /* webpackChunkName: "component---src-pages-start-index-tsx" */)
}

