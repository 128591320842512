import { GlobalState } from "react-gstate";
import Like from "../../images/emoji/like.svg";
import HaHa from "../../images/emoji/haha.svg";
import Sad from "../../images/emoji/sad.svg";
import Love from "../../images/emoji/love.svg";
import Wow from "../../images/emoji/wow.svg";
import Angry from "../../images/emoji/angry.svg";
import { FunctionComponent, SVGAttributes } from "react";

export interface FacebookStateMachineProps {
  pageName: string;
  message: string;
  callToAction: string;
  linkHeadline: string;
  linkDescription: string;
  linkCaption: string;
  pageShares: string;
  pageComments: string;
  reactionCount: string;
  reactionTypes: string[];
  pageLogo: string;
  pageImage: string;
}

export interface Reaction {
  name: string;
  image: FunctionComponent<SVGAttributes<SVGElement>>;
}

export const PossibleReactions = [
  { name: "Like", image: Like },
  { name: "HaHa", image: HaHa },
  { name: "Sad", image: Sad },
  { name: "Love", image: Love },
  { name: "Wow", image: Wow },
  { name: "Angry", image: Angry },
];

class FacebookStateMachine extends GlobalState<FacebookStateMachineProps> {
  setPageName = (input: string, count: number) => {
    FacebookState.setState({ pageName: input.substring(0, count) });
  };

  setMessage = (input: string, count: number) => {
    FacebookState.setState({ message: input.substring(0, count) });
  };

  setCallToAction = (input: string) => {
    FacebookState.setState({ callToAction: input });
  };

  setLinkHeadline = (input: string, count: number) => {
    FacebookState.setState({ linkHeadline: input.substring(0, count) });
  };

  setLinkDescription = (input: string, count: number) => {
    FacebookState.setState({ linkDescription: input.substring(0, count) });
  };

  setLinkCaption = (input: string, count: number) => {
    FacebookState.setState({ linkCaption: input.substring(0, count) });
  };

  setPageShares = (input: string) => {
    const parsedInput = input.replace(/[^0-9.]+/g, "");
    FacebookState.setState({ pageShares: parsedInput });
  };

  setPageComments = (input: string) => {
    const parsedInput = input.replace(/[^0-9.]+/g, "");
    FacebookState.setState({ pageComments: parsedInput });
  };

  changeReactionTypes = (input: string) => {
    console.log("change ev", input);
    if (PossibleReactions.find((x) => x.name === input)) {
      let currentReactions = [...FacebookState.state.reactionTypes];
      if (currentReactions.includes(input)) {
        currentReactions = currentReactions.filter((x) => x !== input);
        console.log(currentReactions);
      } else {
        currentReactions.push(input);
      }
      FacebookState.setState({ reactionTypes: currentReactions });
    }
  };

  setReactionCount = (input: string) => {
    const parsedInput = input.replace(/[^0-9.]+/g, "");
    FacebookState.setState({ reactionCount: parsedInput });
  };

  setPageLogo = (input: File) => {
    if (FacebookState.state.pageLogo) {
      try {
        URL.revokeObjectURL(FacebookState.state.pageLogo);
      } catch (e) {
        console.log(e);
      }
    }
    const blobUrl = URL.createObjectURL(input);
    FacebookState.setState({ pageLogo: blobUrl });
  };

  setPageImage = (input: File) => {
    if (FacebookState.state.pageImage) {
      try {
        URL.revokeObjectURL(FacebookState.state.pageImage);
      } catch (e) {
        console.log(e);
      }
    }
    const blobUrl = URL.createObjectURL(input);
    FacebookState.setState({ pageImage: blobUrl });
  };
}

const FacebookState = new FacebookStateMachine({
  pageName: "",
  message: "",
  callToAction: "",
  linkHeadline: "",
  linkDescription: "",
  linkCaption: "",
  pageShares: "",
  pageComments: "",
  reactionCount: "",
  reactionTypes: ["Like"],
  pageLogo: "",
  pageImage: "",
});

export default FacebookState;
