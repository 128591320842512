import { GlobalState } from "react-gstate";

interface GoogleStateMachineProps {
  ads: AdSet[];
  focusedAdIndex: number;
}

interface AdSet {
  headline1: string;
  headline2: string;
  headline3: string;
  description1: string;
  description2: string;
  displayUrl: string;
}

class GoogleStateMachine extends GlobalState<GoogleStateMachineProps> {
  constructor(props) {
    super(props);
  }

  #updateField = (input: string, adNumber: number, fieldName: string, count: number|null) => {
    const existingItems = [...GoogleState.state.ads];
    const newO = { ...existingItems[adNumber] };
    newO[fieldName] = count ? input.substring(0, count) : input;
    delete existingItems[adNumber];
    existingItems[adNumber] = newO;
    GoogleState.setState({ ads: existingItems });
  };

  setFocusedAd = (index: number) => {
    GoogleState.setState({ focusedAdIndex: index });
  };

  updateHeadline1 = (input: string, adNumber: number, count: number) => {
    this.#updateField(input, adNumber, "headline1", count);
  };

  updateHeadline2 = (input: string, adNumber: number, count: number) => {
    this.#updateField(input, adNumber, "headline2", count);
  };

  updateHeadline3 = (input: string, adNumber: number, count: number) => {
    this.#updateField(input, adNumber, "headline3", count);
  };

  updateDescription1 = (input: string, adNumber: number, count: number) => {
    this.#updateField(input, adNumber, "description1", count);
  };

  updateDescription2 = (input: string, adNumber: number, count: number) => {
    this.#updateField(input, adNumber, "description2", count);
  };

  updateDisplayUrl = (input: string, adNumber: number, count: number) => {
    this.#updateField(input, adNumber, "displayUrl", null);
  };
}

const GoogleState = new GoogleStateMachine({
  focusedAdIndex: 0,
  ads: [
    {
      headline1: "",
      headline2: "",
      headline3: "",
      description1: "",
      description2: "",
      displayUrl: "",
    },
    {
      headline1: "",
      headline2: "",
      headline3: "",
      description1: "",
      description2: "",
      displayUrl: "",
    },
    {
      headline1: "",
      headline2: "",
      headline3: "",
      description1: "",
      description2: "",
      displayUrl: "",
    },
    {
      headline1: "",
      headline2: "",
      headline3: "",
      description1: "",
      description2: "",
      displayUrl: "",
    },
    {
      headline1: "",
      headline2: "",
      headline3: "",
      description1: "",
      description2: "",
      displayUrl: "",
    },
    {
      headline1: "",
      headline2: "",
      headline3: "",
      description1: "",
      description2: "",
      displayUrl: "",
    },
    {
      headline1: "",
      headline2: "",
      headline3: "",
      description1: "",
      description2: "",
      displayUrl: "",
    },
  ],
});

export default GoogleState;
