import React from "react";
import * as Chakra from "@chakra-ui/react";
import { FacebookAdMockup } from "../facebook/facebook-ad-mockup";
import RendererState from "./renderer-state";
import { EmailNewsletterMockup } from "../email-newsletter/email-newsletter-mockup";
import { GoogleMockup } from "../google/google-mockup";
import { LandingPageMockup } from "../landing-page/landing-page-mockup";

import GoogleImage from "../../images/pdf/GoogleAds.png";
import FacebookImage from "../../images/pdf/Facebook.png";
import EmailImage from "../../images/pdf/EDM.png";
import LandingPageImage from "../../images/pdf/Website.png";

export interface RendererProps {}

export const Renderer = ({}: RendererProps) => {
  const googleRef = React.useRef();
  const fbRef = React.useRef();
  const emailRef = React.useRef();
  const landingPageRef = React.useRef();

  const shouldRender = RendererState.useGlobalState((gs) => gs.shouldRender);

  React.useEffect(() => {
    if (shouldRender) {
      RendererState.renderRefs([
        { ref: googleRef, underlay: GoogleImage },
        { ref: fbRef, underlay: FacebookImage },
        { ref: emailRef, underlay: EmailImage },
        { ref: landingPageRef, underlay: LandingPageImage },
      ]);
    }
  }, [shouldRender]);

  return (
    <>
      <Chakra.Flex
        zIndex="-1000"
        ref={googleRef}
        width="595px"
        height="842px"
        backgroundColor="white"
        position="fixed"
        alignItems="center"
        px="60px"
      >
        <GoogleMockup isInRender />
      </Chakra.Flex>
      <Chakra.Flex
        zIndex="-1000"
        ref={fbRef}
        width="595px"
        height="842px"
        backgroundColor="white"
        position="fixed"
        alignItems="center"
        px="60px"
      >
        <FacebookAdMockup isInRender />
      </Chakra.Flex>
      <Chakra.Flex
        zIndex="-1000"
        ref={emailRef}
        width="595px"
        height="842px"
        backgroundColor="white"
        position="fixed"
        alignItems="center"
        px="60px"
      >
        <EmailNewsletterMockup scale={1} />
      </Chakra.Flex>
      <Chakra.Flex
        zIndex="-1000"
        ref={landingPageRef}
        width="595px"
        height="842px"
        backgroundColor="white"
        position="fixed"
        alignItems="center"
        px="60px"
      >
        <LandingPageMockup scale={1} />
      </Chakra.Flex>
    </>
  );
};
