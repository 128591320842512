import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import Header from "../components/header";
import "keen-slider/keen-slider.min.css";
import "./layout.css";
import { Renderer } from "../components/pdf/renderer";
import PageTransitionLarge from "../components/transitions/page-transition-large";
import FullLogo from "../images/full-logo.svg";
import FadeTransition from "../components/transitions/fade-transition";
import { StartWrapper } from "../components/start-wrapper";

const Layout = ({ children, location }: PageProps): React.ReactElement => {
  return (
    <>
      <Renderer />
      <Chakra.Box
        position="fixed"
        width="100%"
        height="100vh"
        backgroundColor="#F8F7F6"
      />
      <PageTransitionLarge location={location}>
        {location.pathname === "/" || location.pathname === "/finish" ? (
          children
        ) : (
          <Chakra.Flex
            minH="100vh"
            width="100%"
            flexDirection="column"
            bgColor="#F8F7F6"
            p={{ lg: "30px" }}
          >
            <Chakra.Flex flexGrow={1} bgColor="white" flexDirection="column">
              <Header location={location} />
              <FadeTransition
                shouldChange={
                  !location.pathname.includes("start")
                    ? location.pathname
                    : "inside"
                }
              >
                {location.pathname.includes("start") ? (
                  <StartWrapper shouldChange={location.pathname}>
                    {children}
                  </StartWrapper>
                ) : (
                  children
                )}
              </FadeTransition>
            </Chakra.Flex>
          </Chakra.Flex>
        )}
      </PageTransitionLarge>
      <Chakra.Box
        pl="30px"
        pt="30px"
        pos="fixed"
        top="0"
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        zIndex="20"
        as={Link}
        to={
          location.pathname === "/start" || location.pathname === "/finish"
            ? "/"
            : "/start"
        }
      >
        <FullLogo />
      </Chakra.Box>
    </>
  );
};

export default Layout;
