import React from "react";
import { GlobalState } from "react-gstate";
import html2canvas from "html2canvas";
import CoverImage from "../../images/pdf/Cover.png";
import ResourcesImage from "../../images/pdf/Resources.png";
import IntroImage from "../../images/pdf/Outro.png";
import OutroImage from "../../images/pdf/Outro.png";

interface RendererStateProps {
  isRendering: boolean;
  shouldRender: boolean;
  renderInfo: string;
  emailAddress: string;
  hasRendered: boolean;
}

interface RenderInputProps {
  ref: React.RefObject<HTMLDivElement>;
  underlay: string;
}

class RendererStateMachine extends GlobalState<RendererStateProps> {
  //@ts-ignore
  private jsPDF: any = null;
  private pdf: any;

  constructor(props) {
    super(props);
    let jsPDF = null;
    if (typeof window !== "undefined") {
      import("jspdf").then((module) => {
        jsPDF = module.default;
        this.pdf = new jsPDF({
          orientation: "landscape",
          compressPdf: true,
        });
      });
    }
  }

  #getBase64FromImageUrl = async (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.addEventListener("load", () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        resolve(canvas.toDataURL("image/png"));
      });
      img.src = url;
    });
  };

  #doRenderOfComponent = async ({ ref, underlay }) => {
    await this.#doRenderOfUnderlay(underlay);

    const domElement = ref.current;
    const canvas = await html2canvas(domElement);
    const imgData = canvas.toDataURL("image/png");
    const imgProps = this.pdf.getImageProperties(imgData);

    const pdfWidth = this.pdf.internal.pageSize.getHeight();
    const pdfHeight = this.pdf.internal.pageSize.getWidth();

    console.log("pdf width", pdfWidth);
    console.log("pdf height", pdfHeight);

    const imageWidth = pdfWidth / 1.7;
    const imageHeight = (imgProps.height * imageWidth) / imgProps.width;

    this.pdf.addImage(
      imgData,
      "PNG",
      125 + (imageWidth - 125 / 2) / 2,
      (pdfWidth - imageHeight) / 2,
      imageWidth,
      imageHeight,
      undefined,
      "SLOW"
    );
  };

  #doRenderOfUnderlay = async (image) => {
    const imgData = await this.#getBase64FromImageUrl(image);

    const pdfWidth = this.pdf.internal.pageSize.getWidth();
    const pdfHeight = this.pdf.internal.pageSize.getHeight();

    this.pdf.addImage(
      imgData,
      "PNG",
      0,
      0,
      pdfWidth,
      pdfHeight,
      undefined,
      "SLOW"
    );
  };

  renderRefs = async (renderInputs: RenderInputProps[]) => {
    RendererState.setState({ isRendering: true });

    const totalCount = renderInputs.length;

    await this.#doRenderOfUnderlay(CoverImage);
    //this.pdf.addPage();
    //await this.#doRenderOfUnderlay(IntroImage);

    let t = 1;
    for (const renderInput of renderInputs) {
      RendererState.setState({
        renderInfo: `Rendering Page ${t} of ${totalCount}`,
      });
      this.pdf.addPage();
      await this.#doRenderOfComponent(renderInput);
      t++;
    }

    this.pdf.addPage();
    await this.#doRenderOfUnderlay(ResourcesImage);

    this.pdf.addPage();
    await this.#doRenderOfUnderlay(OutroImage);

    RendererState.setState({ renderInfo: `Emailing to you...` });

    fetch("/.netlify/functions/finish", {
      method: "POST",
      headers: {
        "x-email-receiver": RendererState.state.emailAddress,
      },
      body: this.pdf.output(),
    }).then((response) => {
      if (response.ok) {
        RendererState.setState({
          isRendering: false,
          shouldRender: false,
          renderInfo: "",
          hasRendered: true,
        });
      }
    });
  };

  shouldRender = () => {
    if (!RendererState.state.isRendering && !RendererState.state.shouldRender) {
      RendererState.setState({ shouldRender: true });
    }
  };

  setEmail = (email) => {
    RendererState.setState({ emailAddress: email });
  };
}

const RendererState = new RendererStateMachine({
  isRendering: false,
  shouldRender: false,
  hasRendered: false,
  renderInfo: "",
  emailAddress: "",
});

export default RendererState;
